import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    appointmentId: Number,
    eventId: Number
  }

  connect() {
    // this controller will target:
    // the buttons where the user finalizes an appointment
    // the radio button that logs rsvp response
  }

  submitAttendingResponse() {
    let data_hash = {};
    let url = '';
    let badges_to_remove = '';
    let error_console = '';

    if (this.hasAppointmentIdValue) {
      let appointment_id = this.appointmentIdValue;
      data_hash.id = appointment_id;
      url = `/my/appointments/${appointment_id}/finalized_meeting`;
      badges_to_remove = document.querySelectorAll(`[data-user-appointment-id="${appointment_id}"]`);
      console.error("Error getting not finalized meeting: ");
    }

    if (this.hasEventIdValue) {
      let event_id = this.eventIdValue;
      data_hash.id = event_id;
      url = `/my/events/${event_id}/rsvped_to`;
      badges_to_remove = document.querySelectorAll(`[data-upcoming-event-id="${event_id}"]`)
      console.error("Error getting upcoming event: ");
    }

    $.ajax({
      type: 'get',
      url: url, // create end point that checks all the types inside def combined_schedules_in_month
      data: data_hash,
      success: function (data) {
        if (data == false) {
          badges_to_remove.forEach(element => {
            element.style.display = 'none';
          });
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.error(error_console, errorThrown);
      }
    })
  }
}
