// used in the user application list
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['moveUp', 'moveDown']

  // if we find more elements that have .move-up, .move-down we will add it's parent container here
  buttonParentClasses = 'div.grid-cell, div.field-group-entry, .drawer-item';

  /*
    NOTES: For the future:
    use target to handle the dom elements once we refactor the other js code
    data-controller="move-up-down" is on the .list-drawer-items and #n-group-**

    might get a little tricky here since there are other children that we need to skip
    like the .ngroup-heading and .ngroup-template. maybe for the new refactor, we'll
    change the layout and set it up so its easier to access
    
    <div class="n-group-heading show_privacy_switch">
    <div class="field-group-entry">
    <div class="ngroup-template">
  */

  connect() {
    FoundationMethods.initialize();
    this.update_disabled(); // this will connect with every node change on the DOM
  }

  update_disabled() {
    let controller = this.element;

    // need to add `.filter(this.buttonParentClasses)` since some ngroups have other children elements
    let $parent = $(controller).parent().children().filter(this.buttonParentClasses);

    $('.move-up').removeClass('disabled').attr('data-action', 'click->move-up-down#moveUp');
    $('.move-down').removeClass('disabled').attr('data-action', 'click->move-up-down#moveDown');

    if ($parent.length <= 1) {
      this.handleDisabledClassAndAttr($('.move-up'));
      this.handleDisabledClassAndAttr($('.move-down'));
    }

    // disable the first child's move-up button
    const $first_move_up_button = $parent.first().find('.move-up');
    this.handleDisabledClassAndAttr($first_move_up_button);

    // disable the last child's move-down button
    const $last_move_down_button = $parent.last().find('.move-down');
    this.handleDisabledClassAndAttr($last_move_down_button);
  }

  handleDisabledClassAndAttr(button) {
    button.addClass('disabled');
    button.removeAttr('data-action');
  }

  animateCss(animate1, animate2, distance) {
    animate1.animate({ top: -distance }, 500),
    animate2.animate({ top: distance }, 500)
  }

  swapPositionsCss(clickedDiv, targetDiv, insertBefore1, insertBefore2) {
    clickedDiv.removeClass('animated');
    targetDiv.removeClass('animated');

    targetDiv.css('top', '0px');
    clickedDiv.css('top', '0px');

    insertBefore1.insertBefore(insertBefore2);
    insertBefore2.insertBefore(insertBefore1);
  }

  setFocusOnFirstLabel(clickedDiv) {
    const $first_label = clickedDiv.find('label').first();
    const $first_label_id = $first_label.attr('for');
    const $id = $first_label_id ? $first_label_id : ($first_label.attr('for') + '-selectized');
    $('#' + $id).focus();
  }

  makeAjaxCall(url) {
    $.ajax({
      url: url,
      method: "POST",
      success: () => {
        console.log("Element moved successfully");
      },
      error: (xhr, status, error) => {
        console.error("Failed to move element:", error);
      }
    });
  }

  moveUp(e) {
    e.preventDefault();
    let this_controller = this;
    let clickedDiv = $(e.currentTarget).closest(this.buttonParentClasses);
    let prevDiv = clickedDiv.prev();

    if (!clickedDiv.is(":first-child")) {
      const url = clickedDiv.find('a.move-up').attr('href');

      $.when(
        this_controller.animateCss(clickedDiv, prevDiv, (clickedDiv.offset().top - prevDiv.offset().top))
      ).done(function () {
        this_controller.swapPositionsCss(clickedDiv, prevDiv, prevDiv, clickedDiv);
        this_controller.setFocusOnFirstLabel(clickedDiv);
        rerank_table();

        /* instead of calling `/my/applications/<%= id %>/move_up` inside the <a> href we will do it here */
        if (url) {
          this_controller.makeAjaxCall(url)
        }
      });
    }
  }

  moveDown(e) {
    e.preventDefault();
    let this_controller = this;
    let clickedDiv = $(e.currentTarget).closest(this.buttonParentClasses);
    let nextDiv = clickedDiv.next();

    if (!clickedDiv.is(":last-child")) {
      const url = clickedDiv.find('a.move-down').attr('href');

      $.when(
        this_controller.animateCss(nextDiv, clickedDiv, (nextDiv.offset().top - clickedDiv.offset().top))
      ).done(function () {
        this_controller.swapPositionsCss(clickedDiv, nextDiv, clickedDiv, nextDiv);
        this_controller.setFocusOnFirstLabel(clickedDiv);
        rerank_table();

        /* instead of calling `/my/applications/<%= id %>/move_down` inside the <a> href we will do it here */
        if (url) {
          this_controller.makeAjaxCall(url)
        }
      });
    }
  }
}
