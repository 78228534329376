import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['remove']

  connect() {
    var this_controller = this
    this.update_disabled($('div.n-group'));

    $.each($('div.n-group'), function() {
      if ($(this).children().first().hasClass('auto-add')) {
        // $(this).find('a[data-add-entry="add-entry"]').trigger("click");
        this_controller.attachNGroup($(this).find('a[data-add-entry="add-entry"]'))
      }
    });
  }

  // One of the links will be the template
  attach(event) {
    this.attachNGroup(event.target)
  }

  attachNGroup(n_group) {
    var new_index = 0;
    var $add_entry_container = $(n_group).closest('.add-entry-container');
    var $n_group = $add_entry_container.closest('.n-group');
    var $first_ngroup_template = $n_group.find('.ngroup-template');

    this.update_disabled($n_group);

    // Get the highest index of the counter
    $n_group.find('.counter').each(function() {
      var value = parseInt($(this).attr('value'));
      new_index = (value >= new_index) ? (value + 1) : new_index;
    });
    /*
      Clone a blank hidden group below the existing template like this
      x------------x
      |            |
      |   hidden   |
      |            |
      x------------x
      x------------x
      |            |
      |   cloned   |
      |            |
      x------------x
    */

    $add_entry_container.before($n_group.find('.ngroup-template').clone());
    $first_ngroup_template.replaceWith($first_ngroup_template.children());
    // To make sure the labels have one-to-one correspondence
    var $insertion = $add_entry_container.prev().prev();

    $insertion.find(".counter[value='-1']").attr('value', new_index);
    var $labels = $insertion.find('label');

    $.each($labels, function() {
      if($(this).attr('for')) {
        $(this).attr('for', $(this).attr('for').replace(/-1/g, new_index));
      }
    });

    var $controls = $insertion.find('input,select,textarea');

    $.each($controls, function() {
      if ($(this).attr('id') !== undefined) $(this).attr('id', $(this).attr('id').replace(/-1/g, new_index));
      if ($(this).attr('name') !== undefined) $(this).attr('name', $(this).attr('name').replace(/-1/g, new_index));
    });

    $insertion.find('select.type-ahead[id$="' + new_index + '"]').each(function() {
      $(this).selectize({
        plugins: ['selectize-plugin-a11y'],
        onChange: function(value) {
          $('textarea[name^="other"][id$="' + new_index + '"]').toggle($.inArray("<%= OTHER.to_s %>", value) != -1);
        },
        onClick: function(value) {
          $(this).open();
        }
      });
    });

    $insertion.find('select[multiple][id$="' + new_index + '"]').each(function() {
      $(this).selectize({
        plugins: ['remove_button', 'selectize-plugin-a11y'],
        onChange: function(value) {
          $(this).next().next('.other-box').toggle($.inArray("<%= OTHER.to_s %>", value) != -1);
        },
        onClick: function(value) {
          $(this).open();
        },
        closeAfterSelect: true
      });
    });
  }

  update_disabled(n_group) {
    $.each(n_group, function() {
      var entries = $(this).find('div.field-group-entry');
      entries.each(function(index) {
        $(this).find('.move-up').toggleClass('disabled', (index == 0));
        $(this).find('.move-down').toggleClass('disabled', (index == (entries.length - 1)));
      });
    });
  }
}
