import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values={
    circleId: Number,
    targetId: Number
  }

  fetchOlderMessages() {
    let stop_loading = ($(this.element).find('.stop-loading').length > 0)

    if (this.element.scrollTop === 0 && !stop_loading) {
      let url = ''
      if (this.hasCircleIdValue) {
        url = `/my/circles/${this.circleIdValue}/messages/older`
      } else {
        url = `/my/connections/${this.targetIdValue}/messages/older`
      }

      if (this.hasCircleIdValue || this.hasTargetIdValue) {
        let first_activity = document.querySelector('div[data-activity-id]:first-child');
        let first_activity_timestamp = null;

        let $activities = $('.chat-message-form-container .activities');
        let old_height = $('#log').height();
        let old_scroll = $activities.scrollTop();

        if (first_activity) {
          first_activity_timestamp = first_activity.dataset.activityTimestamp;
        }

        $.ajax({
          url: url,
          method: 'GET',
          data: { timestamp: first_activity_timestamp },
          dataType: 'html',
          beforeSend: function() {
            $('.loading-spinner').css('display', 'flex');
          },
          success: function(html) {
            $('#log').prepend(html);
            $activities.scrollTop(old_scroll + $('#log').height() - old_height);
            $('.loading-spinner').css('display', 'none');
          },
          error: function(error) {
            $('.loading-spinner').css('display', 'none');
            console.error("Error fetching older activities:", error);
          }
        });
      }
    }
  }
}
