// This file bridges the nested form methods with jquery
// to replace the cocoon:after-insert event
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  afterInsert(e) {
    $(document).trigger('after-insert')
  }
}