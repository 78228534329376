// Make sure this file is the last vite_javascript_tag script tag after all the imports and global variables
import Rails from "@rails/ujs"

Rails.start()

open_modal();

initialize_box();
initialize_sortables();
initialize_tabs();
change_on_form_select();
select_all();
deselect_all();

TruncateText.initialize();
ReinitializeTruncate.initialize();
TruncateElement.initialize();
TruncateElementMaster.initialize();

initialize_remote_errors();
initialize_errors();

page_state();
internal_links_handler();
more_options();
toggle_help();
delete_row();
close_button();
calendar_tooltip_handler();
toggle_tags_handler();
copy_to_clipboard();

auto_reinitialization();

initialize_selectize();
initialize_users_selectize();
initialize_animations();
initialize_survey_matrix();

initialize_date_time_picker_dynamics();

// automerge_columns is used only inside the admin/survey aggregate table
automerge_columns();

disable_enter_submission();
display_upload();
grid_draggable();
jump_link();
navigate_tabs();
privacy_master_switch();
privacy_switch();
sort_cards();
table_sortable();
update_triggering_fields();
update_triggering_sfields();

// attach_n_group_delete();
toggle_switch_focus();
focus_on_open_modal();
//show_tooltip_on_hover();

common_initializations();

import { Application } from "@hotwired/stimulus"
import { registerControllers } from 'stimulus-vite-helpers'

const application = window.Stimulus = Application.start()
const controllers = import.meta.glob('../../assets/javascripts/controllers/**/*_controller.js', { eager: true })

registerControllers(application, controllers)

// Stimulus components from stimulus-components.com

import NestedForm from 'stimulus-rails-nested-form'
application.register('nested-form', NestedForm)