import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["attribute", "preview", "previewUrl"];

  connect() {
    this.updatePreview()
  }

  updatePreview() {
    var preview_box = this.previewTarget;
    var preview_url = this.previewUrlTarget.value;
    var attribute = this.attributeTarget.value;

    if (attribute.length > 0) {
      $.ajax({
        url: preview_url,
        method: 'get',
        type: 'script',
        data: { 'attr': attribute },
        dataType: 'text',
        success: function(data) {
          preview_box.innerHTML = data;
        }
      });
    }
  }
}
