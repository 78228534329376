import { Controller } from "@hotwired/stimulus"
import { display_alert } from '../../../../frontend/xinspire/admin/display_alert'
import { initialize_data_tables } from '../../../../frontend/xinspire/admin/initialize_data_tables'

export default class extends Controller {
  static targets = [
    "selectQuery",
    "updateQueryButton",
    "deleteQueryButton",
    "saveNewQueryButton",
    "uqueryId",  // uqueryId is for the parameter that's passed through the URL
    "refreshUquery",
    "queryParamsId",
    "queryParamsTitle",
    "queryParams"
  ]

  connect() {
    var this_controller = this;

    if (this.hasUqueryIdTarget) {
      this.selectQueryTarget.value = this.uqueryIdTarget.value
      // For forcing a select reload when linked from another page
      if (location.hash.indexOf('reload') != -1) updateSelectQuery()
    }

    if (this.hasRefreshUqueryTarget) {
      this.updateSelectQuery()
    }

    if (this.hasQueryParamsTarget) {
      this.loadForm(
        this.queryParamsIdTarget.value,
        this.queryParamsTitleTarget.value,
        JSON.parse(decodeURIComponent(this.queryParamsTarget.value))
      );
    }

    // Listeners for Filter By and Group By select changes
    $('form').on('change', "select[name^='filter_by']", function(e) {
      this_controller.update_values('filter', $(this));
    });

    $('form').on('change', "select[name^='group_by']", function(e) {
      this_controller.update_values('group', $(this));
    });

    $('form').on('change', '#groups', function(e, column_loading) {
      this_controller.handleGroupsChange(column_loading)
    });

    $('form').on('change', '#programs', function(e, column_loading) {
      this_controller.handleProgramChange(column_loading)
    });

    this.initializeSelectize()
    this.updateButtons()
  }

  initializeSelectize() {
    // Initialization
    $('#programs').selectize({ plugins: ['remove_button'] });
    $('#groups').selectize({ plugins: ['remove_button'] });
    this.initializeColumns();
  }

  initializeColumns(callback) {
    $('#columns').selectize({
      plugins: ['remove_button'],
      onInitialize: ((callback !== undefined) ? callback : function() {}),
      render: {
        item: function(item, escape) {
          return '<div>' + item.text.split(' | ')[0] + '</div>';
        },
        option: function(item, escape) {
          let display_string_array = item.text.split(' | ')

          if (display_string_array.length == 1) {
            return '<div>' +
                '<span class="title"><span class="name">' + escape(display_string_array[0]) + '</span></span>' +
              '</div>';
          } else {
            return '<div>' +
                '<span class="title"><span class="name">' + escape(display_string_array[0]) + '</span></span>' +
                '<span class="description">' + escape(display_string_array[1]) + '</span>' +
              '</div>';
          }
        }
      }
    });
  }

  ///////////////////////////////////////////////////////
  // Handlers
  ///////////////////////////////////////////////////////

  handleUpdateClick(e) {
    var this_controller = this

    var query_id = this.selectQueryTarget.value

    $.ajax({
      url: '/admin/reports/uqueries/update_query',
      method: 'post',
      type: 'script',
      data: $('form').serialize(),
      success: function(data) {
        this_controller.selectQueryTarget.innerHTML = data
      },
      complete: display_alert
    });
    e.preventDefault();
  }

  handleSaveNewClick(e) {
    var this_controller = this

    $.ajax({
      url: '/admin/reports/uqueries',
      method: 'post',
      type: 'script',
      data: $('form').serialize(),
      success: function(data) {
        this_controller.selectQueryTarget.innerHTML = data
        this_controller.updateSelectQuery()
      },
      complete: display_alert
    });
    e.preventDefault();
  }

  handleDeleteClick(e) {
    var this_controller = this;

    if (confirm('This will delete the uquery for you and all other admins.  Are you sure?')) {
      $.ajax({
        url: '/admin/reports/uqueries/delete_query',
        method: 'delete',
        data: $('form').serialize(),
        success: function(data) {
          this_controller.selectQueryTarget.innerHTML = data
          this_controller.clearForm()
          this_controller.updateSelectQuery()
        },
        complete: display_alert
      });
    }
    e.preventDefault();
  }

  handleRunClick(e) {
    $('#results').innerHTML = '<div class=\"muted center padded-3em note large-text\"><i><i title=\"Working\" class=\"fa fa-spinner pulse fas\"><\/i> Loading...<\/i><\/div>'
    this.run_query();
    e.preventDefault();
  }

  handleAddFilterByClick(e) {
    this.add_by_field('filter');
    e.preventDefault();
  }

  handleAddGroupByClick(e) {
    this.add_by_field('group');
    e.preventDefault();
  }

  handleRestrictColumnsToGroupsChange(e) {
    this.handleGroupsChange(false)
  }

  handleProgramChange(column_loading) {
    return this.update_groups(column_loading)
  }

  handleGroupsChange(column_loading) {
    if (column_loading) return;

    var vals = $('#columns').val();

    this.update_data_columns(function() {
      $("#columns")[0].selectize.setValue(vals);
    });

    this.update_filter_and_group_by_selects();
  }

  ///////////////////////////////////////////////////////
  // Custom functions
  ///////////////////////////////////////////////////////

  updateButtons() {
    this.updateQueryButtonTarget.disabled = (this.selectQueryTarget.value == '')
    this.deleteQueryButtonTarget.disabled = (this.selectQueryTarget.value == '')
  }

  // Handler for changing select
  updateSelectQuery() {
    this.loadQuery(this.selectQueryTarget.value)
  }

  loadQuery(query_id) {
    var this_controller = this;

    // Remove existing elements
    this_controller.clearForm();

    if (query_id != '' && query_id !== null) {
      $.ajax({
        type: 'get',
        url: '/admin/reports/uqueries/get',
        data: { query_id: query_id },
        dataType: 'json',
        success: function(data) {
          $.get('/admin/reports/uqueries/titles', {}, function(titles_data) {
            let all_titles = titles_data;
            this_controller.loadForm(query_id, all_titles[query_id], data);
          });
        }
      });
    } else {
      // Reset form
      $('#restrict_columns_to_groups').prop('checked', true);
      $('#include_only_profiles').prop('checked', false);
    }

    this_controller.updateButtons()
  }

  clearForm() {
    // Remove existing elements
    $('#programs')[0].selectize.clear();
    $('#groups')[0].selectize.clear();
    $('#columns')[0].selectize.clear();
    $('#query-title').val('');
    $('#filter-by-table').empty();
    $('#group-by-table').empty();
    $('#favorite').prop('checked', false);
    $("#active_users_only").prop("checked", true);
    $("#include_only_profiles").prop("checked", false);
    $("#restrict_columns_to_groups").prop("checked", true);
  }

  run_query() {
    $.ajax({
      url: '/admin/reports/uqueries/run',
      method: 'post',
      type: 'html',
      data: $('form').serialize(),
      success: function (data) {
        location.hash = '';

        $('#show-query').html('Show Query');
        $('#results').effect('highlight');
        $('#query-section').slideUp(400);

        initialize_data_tables();
      }
    });
  }

  update_filter_and_group_by_selects() {
    var this_controller = this;

    var program_ids_array = $('#programs').val();
    var group_ids_array = $('#groups').val();
    var restrict_columns_to_groups = $('#restrict_columns_to_groups').is(':checked');

    $.get('/admin/reports/uqueries/fields', { program_ids: program_ids_array, group_ids: group_ids_array, restrict_columns_to_groups: restrict_columns_to_groups, include_keyword: true }, function(data) {
      $.each($("select[name^='filter_by']"), function() {
        var vals = $(this).val();
        $(this).html(data);
        $(this).val(vals);
        if (!$(this).val()) {
          this_controller.update_values('filter', $(this));
        }
      });
    });

    $.get('/admin/reports/uqueries/fields', { program_ids: program_ids_array, group_ids: group_ids_array, restrict_columns_to_groups: restrict_columns_to_groups, include_keyword: false }, function(data) {
      $.each($("select[name^='group_by']"), function() {
        var vals = $(this).val(); // save what had been selected first
        $(this).html(data);
        $(this).val(vals);
        if (!$(this).val()) {
          this_controller.update_values('group', $(this));
        }
      });
    });
  }

  selectize_field_by(elem) {
    return $(elem).selectize({
      render: {
        item: function(item, escape) {
          return '<div>' + item.text.split(' | ')[0] + '</div>';
        },
        option: function(item, escape) {
          let display_string_array = item.text.split(' | ')

          if (display_string_array.length == 1) {
            return '<div>' +
                '<span class="title"><span class="name">' + escape(display_string_array[0]) + '</span></span>' +
              '</div>';
          } else {
            return '<div>' +
                '<span class="title"><span class="name">' + escape(display_string_array[0]) + '</span></span>' +
                '<span class="description">' + escape(display_string_array[1]) + '</span>' +
              '</div>';
          }
        }
      }
    });
  }

  // "filter by" or "group by"
  add_by_field(by_name, key) {
    var ts = new Date().getTime();
    if (key !== undefined) ts = key.substring(1); // drop the x, since we add it later

    $('#'+by_name+'-by-table').append($("#row-"+by_name+"-by-template").data('html'));

    // There's a hidden field whose name needs to be changed as well
    $('[name^="' + by_name + '_by[x' + by_name + 'x]"]').prop('name', by_name + '_by[x' + ts + ']');
    $('[name^="' + by_name + '_by_exclude[x' + by_name + 'x]"]').prop('name', by_name + '_by_exclude[x' + ts + ']');
    var last_added_select = $('select[name^=' + by_name + '_by]').last();
    last_added_select.data('id', 'x' + ts);

    this.selectize_field_by(last_added_select);
    this.update_filter_and_group_by_selects();

    return last_added_select;
  }

  update_values(by_name, field_select, callback) {
    var values_section = field_select.closest('.grid-x').find('.values').first();
    var field_name = field_select.val();
    var control_index = field_select.data('id');
    $.get('/admin/programs/fields/list_values', { field_name: field_name, control_index: control_index, by_name: by_name }, function(data) {
      values_section.empty();
      values_section.append(data);
      var values_select = $("select[name='"+by_name+"_values[" + control_index + "][]']");
      var values_input = $("input[name='"+by_name+"_text[" + control_index + "]']");

      values_select.each(function() {
        $(this).selectize();
      });

      if (callback !== undefined) callback();
    }, 'html');
  }

  update_data_columns(callback) {
    var this_controller = this;

    var program_ids_array = $('#programs').val();
    var group_ids_array = $('#groups').val();
    var restrict_columns_to_groups = $('#restrict_columns_to_groups').is(':checked');

    var columns_section = $('#columns').closest('.form-row');

    $.get('/admin/reports/uqueries/data_columns', {program_ids: program_ids_array, group_ids: group_ids_array, restrict_columns_to_groups: restrict_columns_to_groups}, function(data) {
      columns_section.empty();
      columns_section.append(data);

      this_controller.initializeColumns(callback);
    });
  }

  load_by_field_selects(by_name, data) {
    var this_controller = this;

    if (data[by_name+'_by']) {
      $.each(data[by_name+'_by'], function (key, value) {
        var cur_by = this_controller.add_by_field(by_name, key);

        // cur_by.val(value);
        cur_by[0].selectize.setValue(value);

        this_controller.update_values(by_name, cur_by, function () {
          if (data[by_name+'_values'] && data[by_name+'_values'][key]) {
            field = $("select[name='"+by_name+"_values["+key+"][]']")[0];
            if (field) {
              field.selectize.setValue(data[by_name+'_values'][key]);
            }
          }

          if (data[by_name+'_text'] && data[by_name+'_text'][key]) {
            $("input[name='"+by_name+"_text["+key+"]']").val(data[by_name+'_text'][key]);
          }
        });

        // Populate exclude
        if (data[by_name+'_by_exclude'] && data[by_name+'_by_exclude'][key]) {
          if (data[by_name+'_by_exclude'][key] == '1') {
            $("input[name='"+by_name+"_by_exclude["+key+"]']").prop('checked', true);
          }
        }
      });
    }
  }

  loadForm(query_id, title, data) {
    var this_controller = this;

    $('#include_only_profiles').prop('checked', data['include_only_profiles'] == '1');
    $('#active_users_only').prop('checked', data['active_users_only'] == '1');
    $('#separate_columns').prop('checked', data['separate_columns'] == '1');
    $('#favorite').prop('checked', data['favorite'] == '1');
    $('#restrict_columns_to_groups').prop('checked', data['restrict_columns_to_groups'] == '1');
    $('#exclude_private').prop('checked', data['exclude_private'] == '1');
    $('#separate_group_bys').prop('checked', data['separate_group_bys'] == '1');

    // Data should be a JSON structure
    // Fill in the form
    $('#programs')[0].selectize.setValue(data['programs'], true);
    $('#groups')[0].selectize.setValue(data['groups'], true);
    // $('#sessions')[0].selectize.setValue(data['sessions']);

    this.handleProgramChange(true).then((groups_data) => {
      this_controller.update_data_columns(function() {
        $("#columns")[0].selectize.setValue(data['columns']);
      });

      this_controller.load_by_field_selects('filter', data);
      this_controller.load_by_field_selects('group', data);

      // Fills input box with unescaped title text by creating a virtual div with proper text, and grabbing the text.
      // Need to remove program abbreviation.
      $('#query-title').prop('value', $('<div/>').html(title.replace(/\s*(\([^\(\)]+\))$/gm, "")).text());

      this_controller.updateButtons();
      this_controller.update_filter_and_group_by_selects();
    });
  }

  update_groups(column_loading) {
    var this_controller = this

    // update groups based on selected programs
    var dependents = { 'programs': '["groups"]' }
    var dependent_data = {};

    var inputs = {};
    var program_ids = $('#programs').val();

    if (program_ids.length < 1) {
      program_ids = $('#user_accessible_program_ids').val().split(',');
    }
    inputs['programs'] = program_ids;

    // use select_options to update groups
    var updated_groups_promise = new Promise((resolve, reject) => {
      $.ajax({
        type: 'get',
        url: '/admin/select_options',
        data: { inputs: inputs, output_select_ids: ["groups"], dependency_tree: dependents },
        dataType: 'json',
        success: function(data) {
          // data should look like {groups: {0: [12, "Alumni"],
          //                                 1: [25, "Students"]}
          //                       }
          var selectize = $('#groups')[0].selectize;

          // Save existing values
          var existing_values = $('#groups').val();
          dependent_data["programs"] = {};
          dependent_data["programs"]["groups"] = existing_values;
          selectize.clear(true);
          selectize.clearOptions();

          // Update groups options
          var options_array = data["groups"]
          $.each(options_array, function(index, pair) {
            selectize.addOption( { value: pair[0], text: pair[1] } );
          });

          // Reload existing values, if any
          var values = dependent_data["programs"]["groups"]
          if (!values) return;

          $.each(values, function(index, value) {
            selectize.addItem(value, true);
          });

          resolve(data)
        }
      })
    })

    updated_groups_promise.then((data) => {
      this_controller.handleGroupsChange(column_loading)
    })

    return updated_groups_promise
  }
}
