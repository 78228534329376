// Used in /app/components/ui_common/login/component.html.erb
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  delayedClose() {
    // user dashboard compact calendar has a popup that has a link to #modal-box-2
    // if the user clicks on the meeting link, it'll show up inside the #modal-box-2
    // inside the meeting modal, there is a "Edit meeting" button that also opends up inside a #modal-box-2
    // if the meeting is edited and the Submit button is clicked, the #modal-box-2 will close
    // and the popup will be visible on the DOM. that popup should show the new edited info..
    const close_button = this.element.querySelector('.close-button-container button');

    if (close_button) {
      setTimeout(close_button.click(), 200);
    }
  };
}