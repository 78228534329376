// used inside /circles/activities/index.html and /connections/activities/index.html

import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["activities", "body"]

  connect() {
    this.scrollToBottom()
  }

  // on page load we want the scroll to be pinned to the bottom of .activitites
  scrollToBottom() {
    this.activitiesTarget.scrollTop = this.activitiesTarget.scrollHeight;
  }

  // when a new message is appened to the DOM we want the scroll to be pinned to the bottom of .activitites
  pinScrollToBottom() {
    const config = { childList: true };

    // use an arrow function for the callback since arrow functions don't have their own `this`
    const callback = (mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList") {
          this.activitiesTarget.scrollTo(0, this.activitiesTarget.scrollHeight);
          this.bodyTarget.value = '';
          // need to disconnect here so it doesn't clash with the fetch older messages scroll
          observer.disconnect();
        }
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(document.getElementById("log"), config);
  }
}
