import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'link',
    'list',
    'more'
  ]

  connect() {
    this.redistribute()
  }

  redistribute() {
    let account_menu = document.querySelector('[aria-label="Account Menu"]');
    let header = document.querySelector('#header');
    let program_more_dropdown = document.querySelector('.program-more-dropdown');

    let container = this.element;
    let list = this.listTarget;
    let links = this.linkTargets;
    let more_dropdown = this.moreTarget;
    let more_width = 80;
    let account_menu_width = account_menu.clientWidth; // the width of the sign in / notification bell
    let link_width_so_far = 0; // this need to be set to the first link

    let $more_tab = $(more_dropdown).parent().find('a:first-child').first();

    links.forEach(function(link) {
      let link_width = link.clientWidth;

      // Sets an internal variable for the width of the item
      // We'll need it later because once the element is hidden, its clientWidth is 0
      if (link.dataset.width == undefined) {
        link.dataset.width = link_width;
      } else {
        link_width = parseInt(link.dataset.width);
      }

      // this takes care of link's overflowing on page load by appending the links to
      // the More dropdown if the total link's width is wider than the #header's width
      link_width_so_far += link_width;

      if (link_width_so_far + (account_menu_width + 10) + link_width + more_width > header.clientWidth) {
        program_more_dropdown.style.display = 'flex';
        more_dropdown.append(link);
      } else {
        program_more_dropdown.style.display = 'none';
        list.append(link);
      }
    })

    // clear all the .notification-dot from the More tab
    $more_tab.find('.notification-dot').remove();

    // find all the dots inside the dropdown and clone / add it to the More tab
    $(more_dropdown).find('.notification-dot').each((i, element) => {
      $more_tab.append($(element).clone());
    });

    container.classList.remove('invisible');
  }
}