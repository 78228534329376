// Used in /ui_user/edit_forms
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["triggering"]

  connect() {
    var this_controller = this;

    this.triggeringTargets.forEach((triggering) => {
      this_controller.toggle_dependent_fields($(triggering).find("select, input:not(.switch-input):checkbox, input[type='radio']"));
    });
  }

  updateDependent(event) {
    this.toggle_dependent_fields($(event.target));
  };

  toggle_dependent_fields(target) {
    var triggeringField = target.closest(".triggering");
    var ids = triggeringField.data("field");
    var selectedValues = this.get_selected_values(target);
    var this_controller = this;

    var context = triggeringField.parent().closest("div");

    context.find("div.dependent").each( function() {
      var isMatchingField = this_controller.is_overlapping(ids.toString().split(","), $(this).data("triggers").toString().split(","));
      var isMatchingValue = this_controller.is_overlapping(selectedValues, $(this).data("values").toString().split(","));

      if (isMatchingField) {
        // Show the dependent field if user has selected proper values and the triggering field is itself visible  (either automatically visible or visible due to satisfying its own dependency condition)

        var oldState = $(this).hasClass("triggered");
        if(this_controller.is_visible_in_form(triggeringField) && isMatchingValue) {
          $(this).addClass("triggered");
          $(this).show();
        } else {
          $(this).removeClass("triggered");
          $(this).hide();
        }
        var newState = $(this).hasClass("triggered");

        if (oldState != newState && $(this).hasClass("triggering")) {
          this_controller.toggle_dependent_fields($(this).find("select,input:checkbox,input:radio"));
        }
      }
    });
  }

  is_visible_in_form(triggeringField) {
    return !triggeringField.hasClass("dependent") || triggeringField.hasClass("triggered");
  }

  get_selected_values(target) {
    var selected_values;

    if (target.is(":checkbox")) {
      if (target.prop("name").endsWith("[]")) {
        selected_values = target.closest(".triggering").find("input:checkbox:checked").map(function(){
          return $(this).val();
        }).get();
      } else {
        selected_values = (target.is(":checked")) ? ["1"] : ["0"];
      }
    } else if (target.is(":radio")) {
      // The difference between radio and select is that select has a single "input" while
      // each of the radio options is its own input; therefore, each radio button will get
      // its own trigger, which messes up the dependency calculation if each radio button
      // returns a different result.
      //
      // In other words, each radio option gets triggered, and as each one gets called, the
      // same dependent field gets recalculated based on that one single radio button,
      // whose checked-ness changes as one iterates over the radio options.

      // selected_values = (target.is(":checked")) ? [target.val()] : [];
      selected_values = target.closest(".triggering").find("input:radio:checked").map(function(){
        return $(this).val();
      }).get();
    } else if (Array.isArray(target.val())) {
      selected_values = target.val();
    } else {
      selected_values = [target.val()];
    }
    return selected_values;
  }

  is_overlapping(arr1, arr2) {
    var overlap = arr1.filter(function(n) {
      return arr2.indexOf(n) != -1;
    });
    return overlap.length > 0;
  }
}
