import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  download() {
    var parent_form = $(this).closest('form');
    parent_form.find('.download-data')[0].value = parent_form
      .closest('.items-table-wrapper')
      .find('.table-wrapper table thead')
      .html().replace(/—/g, '--')
      .replace(/&nbsp;/g, ' ');
    // Should get all tbodys in table

    var tbody_output = '';
    $.each(parent_form.closest('.items-table-wrapper').find('.table-wrapper table tbody'), function() {
      tbody_output += $(this).html();
    });

    parent_form.find('.download-data')[1].value = tbody_output.replace(/—/g, '--').replace(/&nbsp;/g, ' ');

    parent_form.attr('action', '/admin/utilities/export_table');
    parent_form.submit();
  }
}
