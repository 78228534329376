import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['remove']

  connect() {
  }

  // One of the links will be the template
  delete(event) {
    this.nGroupDelete(event.target)
  }

  nGroupDelete(element) {
    var $n_group = $(element).closest('.n-group');
    var n_group_name = $n_group.children().first().attr('id').replace(/n-group-/, '');

    if (confirm('Are you sure?')) {
      var field_group = $(element).closest('.field-group-entry');
      field_group.remove();
    }

    if ($n_group.find('.field-group-entry').length == 0) {
      var $hidden_destroy_marker = $('<input />').attr({type: 'hidden', name: n_group_name + '_destroy', class: 'destroy-marker', value: 1});
      $n_group.append($hidden_destroy_marker);
    }
  };
}