import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["showIntra"];

  connect() {
    this.handleIntraCheckbox()
  }

  handleIntraCheckbox() {
    var show = this.showIntraTarget.checked

    $('.intra').toggle(show);
  }
}
