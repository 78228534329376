// Used inside views/user/user_steps/_orientation.html.erb << ???
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    activitiesTargetId: Number,
    activitiesCircleId: Number,
    targetId: Number,
    circleId: Number,
  }

  connect() {
    // this controller will connect on these pages:
    // Pair page - Connections
    // Circle page - Connections
    // Messages page - for both pair/circle

    this.update_notification_badge();
  }

  update_notification_badge() {
    let data_hash = {};

    if (this.hasActivitiesTargetIdValue) {
      data_hash.target_id = this.activitiesTargetIdValue;
    }

    if (this.hasActivitiesCircleIdValue) {
      data_hash.circle_id = this.activitiesCircleIdValue;
    }

    // need to use this_controller since `this` inside the ajax call references the $.ajax() and not the actual controller
    let this_controller = this;
    let has_target_id = this_controller.hasActivitiesTargetIdValue;
    let has_circle_id = this_controller.hasActivitiesCircleIdValue;
    let target_id = this_controller.activitiesTargetIdValue;
    let circle_id = this_controller.activitiesCircleIdValue;

    $.ajax({
      type: 'post',
      url: '/my/messages/read',
      data: data_hash,
      success: function (data) {
        // TODO: need to figure out a way to only target the active li tab for the Messages page
        // for example:
        // if two users have a red dot, our current code removes both User 1's and User 2's dot

        // User 1     o <- active li tab
        // User 2     o
        // User 3
        if (has_target_id) {
          let badges_to_remove = document.querySelectorAll(`[data-unread-target-id="${target_id}"]`)
          badges_to_remove.forEach(element => {
            element.style.display = 'none';
          });
        } else if (has_circle_id) {
          let badges_to_remove = document.querySelectorAll(`[data-unread-circle-id="${circle_id}"]`)
          badges_to_remove.forEach(element => {
            element.style.display = 'none';
          });
        }

        // for the connection page messages tab
        if (has_target_id || has_circle_id) {
          document.querySelector('#panel-messages-label .total').innerHTML = '0'
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.error("Error getting read messages:", errorThrown);
      }
    })
  }
}
