// Used inside views/user/user_steps/_orientation.html.erb
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["other", "whichMessage", "makeupMessage", "form"]

  connect() {
    this.otherTarget.style.display = 'none'
  }

  handleSelection(event) {
    const CANNOT_ATTEND = '-1'
    const NOT_LISTED = '0'

    if (event.target.value == NOT_LISTED) {
      this.otherTarget.setAttribute("placeholder", this.whichMessageTarget.innerHTML);
      this.otherTarget.style.display = 'block'
    } else if (event.target.value == CANNOT_ATTEND) {
      this.otherTarget.setAttribute("placeholder", this.makeupMessageTarget.innerHTML)
      this.otherTarget.style.display = 'block'
    } else if (event.target.value != NOT_LISTED || event.target.value != CANNOT_ATTEND) {
      this.otherTarget.style.display = 'none'
    }
  }

  showForm() {
    this.formTarget.style.display = 'block'
  }
}