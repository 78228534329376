// Used in /app/components/ui_common/login/component.html.erb
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fragment"]

  attachFragment(event) {
    this.fragmentTarget.value = window.location.hash;
  };
}
