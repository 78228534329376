import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["file", "existingFile", "submitButton"];

  connect() {
    this.updateButtonState()
  }

  updateButtonState() {
    let no_uploaded_file = !this.fileTarget.value;
    let no_existing_file = !this.existingFileTarget.innerHTML;

    // create:
    // the save button is disable until a file is uploaded
    // no_uploaded_file will be false unless user uploads a file
    // no_existing_file will always be false in this state

    // edit:
    // the save button is enabled at all times
    // no_uploaded_file will be false unless user uploads a file so then we check no_existing_file
    // no_existing_file will always be true in this state since it is required to upload a file to save the form
    this.submitButtonTarget.disabled = (no_uploaded_file && no_existing_file);
  }
}
