import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['remove']

  // On page load since there is only one field showing we will disable the remove button
  //
  // When a user adds a field we will enable the remove button
  // on the first field so they can remove it if they want
  // If the user removes fields and there is only one field left,
  // we will set it back to the default state and disable the remove button
  //
  // on page load or when there is only one field:
  //  ___________________
  // |___________________| x (disabled)
  //
  // when there are multiple fields:
  //  ___________________
  // |___________________| x (enabled)
  //  ___________________
  // |___________________| x
  //  ___________________
  // |___________________| x

  connect() {
    var this_controller = this

    this.updateLinks()
  }

  // One of the links will be the template
  updateLinks() {
    var num_links = this.removeTargets.filter(target=> target.offsetParent).length

    if (num_links <= 1) {
      this.disableLinks()
    } else {
      this.enableLinks()
    }
  }

  updateLinksPreAdd() {
    this.enableLinks()
  }

  updateLinksPreRemove() {
    // Note that the num_links is what it looks like BEFORE the remove or addition happens
    var num_links = this.removeTargets.filter(target=> target.offsetParent).length
    if (num_links <= 2) {
      this.disableLinks()
    }
  }

  disableLinks() {
    this.removeTargets.forEach(button => {
      button.disabled = true
    })
  }

  enableLinks() {
    this.removeTargets.forEach(button => {
      button.disabled = false
    })
  }
}
