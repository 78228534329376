// Used inside user/cropbox_picture.html.erb
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["picture"];

  connect() {
  }

  uploadHandler(event) {
    let input = event.target

    if (input.files && input.files[0]) {
      var reader = new FileReader();
      var preview = input.closest('.controls').parentElement

      reader.preview = preview;
      reader.onload = function (e) {
        preview = this.preview.querySelector('img')
        preview.setAttribute('src', e.target.result);
        preview.classList.remove('hidden');
      }
      reader.readAsDataURL(input.files[0]);
    }
  }
}