import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["mapped", "otherMappings", "myMappings"];

  connect() {

  }

  updateMappings(e) {
    this.myMappingsTarget.hidden = !this.mappedTarget.checked
    this.otherMappingsTarget.hidden = !this.mappedTarget.checked

    if (this.mappedTarget.checked) {
      this.myMappingsTarget.classList.remove('hidden')
      this.otherMappingsTarget.classList.remove('hidden')
    }
  }

  handleOtherCriterionChange(e) {
    if (e.target.value == -6) { // "Prioritize Tag"
      this.mappedTarget.checked = true
      this.mappedTarget.disabled = true
    } else {
      this.mappedTarget.disabled = false
    }

    this.updateMappings(e)
  }
}
