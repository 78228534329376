// Used inside views/user/user_steps/_orientation.html.erb << ???
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["recommendeeId", "submitButton", "body", "mode"]

  connect() {
    // this.handleProgramChange()
    var this_controller = this;

    $('body').on('change', '#recommendee_id', function() {
      this_controller.handleRecommendeeChange();
    });

    this.updateSubmitButton()
  }

  handleRecommendeeChange() {
    var target_id = this.recommendeeIdTarget.value

    if (target_id) {
      $('#target_email').data('users-url', '/admin/users/accounts/recommendation_recipients.json?target_id=' + target_id)
      reload_users_selectize('#target_email')
    }
  }

  updateSubmitButton() {
    const submitButton = this.submitButtonTarget;

    if (!this.hasModeTarget) {
      submitButton.disabled = (this.bodyTarget.value.length == 0);
    } else {
      if (this.modeTarget.value === 'contact' || this.modeTarget.value === 'initial_message') {
        submitButton.disabled = (this.bodyTarget.value.length == 0);
      } else {
        submitButton.disabled = false
      }
    }

    // if the submit button is enabled and the enter keyboard is pressed, it will send the message
    this.bodyTarget.addEventListener('keydown', function(e) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        e.preventDefault();
        if (!submitButton.disabled) {
          submitButton.click();
        }
      }
    })
  }
}
