// Used inside user/user_appointments/_form.html.erb
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["location", "videoChat", "name", "submit", "startDate", "startTime", "endDate", "endTime"]
  static values = { videoUrl: String }

  connect() {
    // Remove the delete functionality for the first field
    $('.user-appointment-times .slot').first().find('.remove_fields').css('visibility', 'hidden');

    this.updateSubmitDisabled();
  }

  updateVideoChat() {
    this.videoChatTarget.checked = this.locationTarget.value.includes(this.videoUrlValue)
  }

  updateLocation() {
    if (this.videoChatTarget.checked) {
      this.locationTarget.value = this.videoUrlValue;
    } else {
      this.locationTarget.value = '';
    }
  }

  updateSubmitDisabled(e, stop_update = false) {
    this.submitTarget.disabled = (
      this.nameTarget.value == '' ||
      this.startDateTarget.value == '' ||
      this.startTimeTarget.value == '' ||
      this.endDateTarget.value == '' ||
      this.endTimeTarget.value == ''
    )

    // This is needed because this handler gets called before the auto-update occurs in date_time_picker.js
    let this_controller = this;
    if (!stop_update) {
      setTimeout(function() {
        this_controller.updateSubmitDisabled(e, true);
      }, 100);
    }
  }
}