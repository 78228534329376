// This controller will support up to 2 sets of criteria/values per line
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    criterionValueClass: String,
    criterionTextClass: String,
    criterionValueIdsClass: String,
    importanceClass: String,
    criterionValueClassB: String,
    criterionTextClassB: String,
    criterionValueIdsClassB: String,
    importanceClassB: String,
    programId: Number
  }

  connect() {

  }

  // target is a jQuery object (for now)
  updateCriterionValues(event) {
    let this_controller = this;
    let target = $(event.target)

    let parent_row = target.closest('.nested-form-wrapper');

    let values_select = parent_row.find(this.criterionValueClassValue);
    let values_selectize_container = values_select.parent();
    let values_text_container = parent_row.find(this.criterionTextClassValue).first().parent();
    let value_ids_select = parent_row.find(this.criterionValueIdsClassValue);
    let value_ids_selectize_container = value_ids_select.parent();
    let importance_select = parent_row.find(this.importanceClassValue);

    let field_id = target.val();

    function clear_selectize_options(sel) {
      // Assume by this point that the select has been selectized
      sel.clear();
      sel.clearOptions();
      sel.clearOptionGroups();
      // There's a bug in clearOptions()
    }

    function load_selectize_options(sel, data) {
      var using_groups = false;
      if ($.isArray(data) && data[0].length != 0) {
        if ($.isArray(data[0]) && $.isArray(data[0][1])) {
          using_groups = true;
        }
      }
      if (using_groups) {
        $.each(data, function(index, row) {
          sel.addOptionGroup(row[0], { label: row[0] });

          $.each(row[1], function(j, r) {
            sel.addOption({
              value: r[1],
              text: r[0],
              optgroup: row[0]
            });
          });
        });
      } else {
        $.each(data, function(index, row) {
          sel.addOption({
            value: row[0],
            text: row[1]
          });
        });
      }
    }

    function update_proper_content_box(data) {
      const GROUPS = -2
      const AVAILABILITY = -3
      const TAG = -5
      const PRIORITIZE_TAG = -6

      const REQUIRED = 26

      if (data.length == 0) {
        values_selectize_container.hide();
        value_ids_selectize_container.hide();
        values_text_container.show();
        importance_select.removeAttr('disabled');
      } else {
        var sel;
        var use_groups = false;

        if (field_id == GROUPS || field_id == AVAILABILITY) {
          sel = value_ids_select[0].selectize;
          values_selectize_container.hide();
          value_ids_selectize_container.show();

          importance_select.val(REQUIRED);
          importance_select.attr('disabled', 'disabled');

          values_text_container.hide();
          clear_selectize_options(sel);

        } else if (field_id == TAG || field_id == PRIORITIZE_TAG) {
          importance_select.removeAttr('disabled');

          sel = value_ids_select[0].selectize;
          values_selectize_container.hide();
          value_ids_selectize_container.show();

          values_text_container.hide();
          clear_selectize_options(sel);
        } else {
          importance_select.removeAttr('disabled');
          sel = values_select[0].selectize;
          values_selectize_container.show();
          value_ids_selectize_container.hide();

          values_text_container.hide();
          clear_selectize_options(sel);
        }

        load_selectize_options(sel, data);

        sel.refreshOptions(false);
      }
    }

    if (field_id != '') {
      values_selectize_container.show();
      value_ids_selectize_container.hide();
      values_text_container.hide();

      $.ajax({
        type: 'get',
        url: '/search/criterion',
        data: ((this_controller.hasProgramIdValue) ? { id: field_id, program_id: this_controller.programIdValue } : { id: field_id }),
        dataType: 'json',
        success: update_proper_content_box
      });
    } else {
      var sel = values_select[0].selectize;
      sel.clear();
      sel.clearOptions();
    }
  }

  // There's probably a way to DRY this up but I don't know how
  updateCriterionValues2(event) {
    let this_controller = this;
    let target = $(event.target)

    let parent_row = target.closest('.nested-form-wrapper');

    let values_select = parent_row.find(this.criterionValueClassBValue);
    let values_selectize_container = values_select.parent();
    let values_text_container = parent_row.find(this.criterionTextClassBValue).first().parent();
    let value_ids_select = parent_row.find(this.criterionValueIdsClassBValue);
    let value_ids_selectize_container = value_ids_select.parent();
    let importance_select = parent_row.find(this.importanceClassBValue);
    let field_id = target.val();

    function clear_selectize_options(sel) {
      // Assume by this point that the select has been selectized
      sel.clear();
      sel.clearOptions();
      sel.clearOptionGroups();
      // There's a bug in clearOptions()
    }

    function load_selectize_options(sel, data) {
      var using_groups = false;
      if ($.isArray(data) && data[0].length != 0) {
        if ($.isArray(data[0]) && $.isArray(data[0][1])) {
          using_groups = true;
        }
      }
      if (using_groups) {
        $.each(data, function(index, row) {
          sel.addOptionGroup(row[0], { label: row[0] });

          $.each(row[1], function(j, r) {
            sel.addOption({
              value: r[1],
              text: r[0],
              optgroup: row[0]
            });
          });
        });
      } else {
        $.each(data, function(index, row) {
          sel.addOption({
            value: row[0],
            text: row[1]
          });
        });
      }
    }

    function update_proper_content_box(data) {
      const GROUPS = -2
      const AVAILABILITY = -3
      const TAG = -5
      const PRIORITIZE_TAG = -6

      const REQUIRED = 26

      if (data.length == 0) {
        values_selectize_container.hide();
        value_ids_selectize_container.hide();
        values_text_container.show();
        importance_select.removeAttr('disabled');
      } else {
        var sel;
        var use_groups = false;

        if (field_id == GROUPS || field_id == AVAILABILITY) {
          sel = value_ids_select[0].selectize;
          values_selectize_container.hide();
          value_ids_selectize_container.show();

          importance_select.val(REQUIRED);
          importance_select.attr('disabled', 'disabled');

          values_text_container.hide();
          clear_selectize_options(sel);

        } else if (field_id == TAG || field_id == PRIORITIZE_TAG) {
          importance_select.removeAttr('disabled');
          sel = value_ids_select[0].selectize;
          values_selectize_container.hide();
          value_ids_selectize_container.show();

          values_text_container.hide();
          clear_selectize_options(sel);
        } else {
          importance_select.removeAttr('disabled');
          sel = values_select[0].selectize;
          values_selectize_container.show();
          value_ids_selectize_container.hide();

          values_text_container.hide();
          clear_selectize_options(sel);
        }

        load_selectize_options(sel, data);

        sel.refreshOptions(false);
      }
    }

    if (field_id != '') {
      values_selectize_container.show();
      value_ids_selectize_container.hide();
      values_text_container.hide();

      $.ajax({
        type: 'get',
        url: '/search/criterion',
        data: ((this_controller.hasProgramIdValue) ? { id: field_id, program_id: this_controller.programIdValue } : { id: field_id }),
        dataType: 'json',
        success: update_proper_content_box
      });
    } else {
      var sel = values_select[0].selectize;
      sel.clear();
      sel.clearOptions();
    }
  }
}
