import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { targetId: Number };
  static targets = ["relationshipBar"]

  connect() {
    this.submitRequestResponse();
  }

  submitRequestResponse() {
    let data_hash = {};
    let has_target_id = this.hasTargetIdValue;
    let this_controller = this;

    if (has_target_id) {
      data_hash.target_id = this.targetIdValue;
    }

    $.ajax({
      type: 'post',
      url: '/my/connections/pending',
      data: data_hash,
      dataType: 'json',
      success: function (data) {
        if (data.pending_connections == false) {
          // check for changes in #log
          this_controller.observeLogChanges(this_controller);
        }

        // change the status icon next to the profile picture
        document.querySelectorAll('.status-icon').forEach(element => {
          if (data.matched === true) {
            element.classList.remove('pending');
            element.querySelector('i').classList.remove('fa-timer');
            element.classList.add('success');
            element.querySelector('i').classList.add('fa-check');
          } else {
            element.style.display = 'none';
          }
        });

        // this is only on the connections page
        // if a user cancels on a user card this element won't be on the DOM
        const relationship_bar = document.querySelector('.relationship-bar-container.top-aligned');
        if (relationship_bar) {
          relationship_bar.style.display = 'none';
        }
      }
    });
  }

  observeLogChanges(this_controller) {
    const log_element = document.getElementById('log');

    if (log_element) {
      const observer = new MutationObserver((mutations) => {
        // this means the #log appended the activity and now the dot can be removed
        if (mutations.length > 0) {
          let badges_to_remove = document.querySelectorAll(`[data-pending-connection-id="${this_controller.targetIdValue}"]`);
          badges_to_remove.forEach(element => {
            element.style.display = 'none';
          });
        }
      });

      const config = { childList: true, subtree: true };
      observer.observe(log_element, config);
    }
  }
}
