import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['going', 'additionalSection']

  connect() {
    this.additionalSectionTarget.classList.remove('hidden') // removes the flash of content
    this.updateAdditionalSection()
  }

  updateAdditionalSection() {
    this.additionalSectionTarget.hidden = !this.goingTarget.checked
  }
}
