// Used inside views/account/_participation.html.erb
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["indefinite_invisibility_checkbox"];

  connect() {
    this.autoToggle()
  }

  autoToggle() {
    let hide_indefinite_invisibility = document.querySelectorAll('[name="user[user_preference_attributes][hide_indefinite_invisibility]"]');
    if (hide_indefinite_invisibility.length > 0) {
      let hide_indefinite_invisibility_row = hide_indefinite_invisibility[0].closest('.form-row')
      hide_indefinite_invisibility_row.style.display = this.indefinite_invisibility_checkboxTarget.checked ? "block" : "none"
    }
  }
}