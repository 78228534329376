import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submitButton", "assignees", "body"]

  connect() {
    var this_controller = this;

    $('form').on('change', $('#user_goal_assignee_ids')[0].selectize, function() {
      this_controller.updateSubmitButton();
    });
  }

  updateSubmitButton() {
    this.submitButtonTarget.disabled = (this.bodyTarget.value.length == 0 || $(this.assigneesTarget).val().length < 1);
  }
}
