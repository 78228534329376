import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    model: String,
    hash: String
  }

  connect() {
    var this_controller = this
    let model = this.modelValue

    // If there is no model passed, skip processing
    if (model.length > 0 && this.hashValue.length > 0) {
      let errors = JSON.parse(decodeURIComponent(this.hashValue))

      $('.form-row').removeClass('error').find('.message').removeClass('error').html('');

      $.each(errors, function(key, value) {
        let selector_string = '[name="' + model + '[' + key + ']"]';
        let selector_string_of_array = '[name="' + model + '[' + key + '][]"]';
        let $input = $(selector_string);

        if ($input.length == 0) $input = $(selector_string_of_array);

        if ($input.length > 0) {
          var input_id = $input.attr('id');

          $input
            .closest('.form-row')
            .addClass('error')
            .find('.message')
            .addClass('error')
            .attr('id', input_id + '_alert')
            .html(value.join(' & ') );

          $input.attr('aria-describedby', input_id + '_alert');
        } else {
          var $error_div = $('<div></div>');
          // In case we don't have a specific field for the error
          // display it at the top of the form.
          // TODO: stylize
          $error_div.html(key + ": " + value)
          $error_div.addClass("message").addClass("error")

          $("#form-errors-container").append($error_div);
        }
      });

      if ($('#form-tabs .form-tab').length > 0) {
        var tab_panel_name = $('.form-row.error').first().closest('.ui-tabs-panel').attr('id');
        $('a[href="#' + tab_panel_name + '"]').click();
      }
    }
  }
}
