import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $(this.element).find('select.criterion-value, select.criterion-value-ids').each(function() {
      $(this).selectize({
        plugins: ['remove_button', 'selectize-plugin-a11y'],
        closeAfterSelect: true
      });
    });
    $('.selectize-input input[type="text"]').attr('aria-label', "Search term");
  }
}
