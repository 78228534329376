// Used inside user/cropbox_picture.html.erb
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["picture", "cropSection"];

  connect() {
    this.pictureTarget.style.display = 'block'

    if (this.hasCropSectionTarget) {
      this.cropSectionTarget.style.display = 'none'
    }

    this.input_disabled(true)
  }

  uploadHandler(event) {
    let input = event.target

    if (input.files && input.files[0]) {
      var reader = new FileReader();
      var preview = input.closest('.controls')

      reader.preview = preview;
      reader.onload = function (e) {
        preview = this.preview.querySelector('img')

        // If preview picture exists, don't create DOM elements and just replace the src
        if (preview) {
          preview.setAttribute('src', e.target.result);
        } else {
          var preview_square = document.createElement("div")
          preview_square.setAttribute('class', 'preview-square')

          var img = document.createElement("img")
          img.setAttribute('class', 'preview')
          img.setAttribute('src', e.target.result)
          img.setAttribute('alt', 'Preview profile picture')

          var heading = document.createElement("div")
          heading.setAttribute('class', 'heading-container')

          var preview_title = document.createElement("div")
          preview_title.setAttribute('class', 'title')

          heading.appendChild(preview_title)
          preview_square.appendChild(heading)
          preview_square.appendChild(img)
          this.preview.appendChild(preview_square)

          document.getElementById("crop-section").style.display = 'none'
        }
      }
      reader.readAsDataURL(input.files[0]);
    }
  }

  cropHandler() {
    if (this.hasCropSectionTarget) {
      this.cropSectionTarget.style.display = 'block'
      this.element.querySelector('.uploader').style.display = 'block'
      this.input_disabled(false)
    }
  }

  finishCropHandler() {
    if (this.hasCropSectionTarget) {
      this.cropSectionTarget.style.display = 'none'
      this.element.querySelector('.uploader').style.display = 'block'
      this.input_disabled(false)
    }
  }

  input_disabled(boolean) {
    if (this.hasCropSectionTarget) {
      let inputs = this.cropSectionTarget.getElementsByTagName('input')
      for (let i = 0; i < inputs.length; ++i) {
        inputs[i].disabled = boolean
      }
    }
  }
}